@media only screen and (max-width: 480px) {
    .apexcharts-xaxis
        .apexcharts-xaxis-texts-g
        .apexcharts-xaxis-label:nth-child(2n) {
        display: none !important;
    }
}

.apexcharts-tooltip,
.apexcharts-tooltip.active,
.apexcharts-xaxistooltip,
.apexcharts-xaxistooltip.active,
.apexcharts-marker {
    transition: none !important;
}
